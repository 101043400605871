export function getAll() {
  return [
    {
      value: 0,
      name: "Manual",
      text: "手動",
    },
    {
      value: 1,
      name: "Auto",
      text: "自動",
    },
  ];
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
