<template>
  <div>
    <modal-part
      modalId="salData"
      modalClass="modal-lg"
      title="銷貨單"
      :hasButtons="false"
      :isShow="isShow"
      @onClosed="onClosed"
    >
      <div slot="item">
        <div class="row" v-if="trade.Sal">
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="salnumber">單號:</label>
              <input
                type="text"
                id="salnumber"
                class="form-control"
                :value="trade.Sal.SalNumber"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="terminalNumber">收銀台:</label>
              <input
                type="text"
                id="terminalNumber"
                class="form-control"
                :value="
                  trade.Sal.TerminalNumber + ' - ' + trade.Sal.TerminalName
                "
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="invoiceTime">開立時間:</label>
              <input
                type="text"
                id="invoiceTime"
                class="form-control"
                :value="trade.Sal.InvoiceTime"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="tradeType">交易別:</label>
              <input
                type="text"
                id="tradeType"
                class="form-control"
                :value="trade.Sal.TradeTypeText"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="businessDay">營業日期:</label>
              <input
                type="text"
                id="businessDay"
                class="form-control"
                :value="trade.Sal.BusinessDay"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="businessClass">班別:</label>
              <input
                type="text"
                id="businessClass"
                class="form-control"
                :value="trade.Sal.BusinessClass"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="invoicenumber">發票號碼:</label>
              <input
                type="text"
                id="invoicenumber"
                class="form-control"
                :value="trade.Sal.InvoiceNumber"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="uniformNumber">統一編號:</label>
              <input
                type="text"
                id="uniformNumber"
                class="form-control"
                :value="trade.Sal.UniformNumber"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="vehicleNumber">載具號碼:</label>
              <input
                type="text"
                id="vehicleNumber"
                class="form-control"
                :value="trade.Sal.VehicleNumber"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="employeeNumber">收銀員:</label>
              <input
                type="text"
                id="employeeNumber"
                class="form-control"
                :value="
                  trade.Sal.EmployeeNumber + ' - ' + trade.Sal.EmployeeName
                "
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="customerNumber">會員:</label>
              <input
                type="text"
                id="customerNumber"
                class="form-control"
                :value="
                  trade.Sal.CustomerNumber == null
                    ? trade.Sal.CustomerNumber
                    : '' + ' - ' + trade.Sal.CustomerName == null
                    ? trade.Sal.CustomerName
                    : ''
                "
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="discount">折扣:</label>
              <input
                type="text"
                id="discount"
                class="form-control"
                :value="trade.Sal.Discount"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="total">總計:</label>
              <input
                type="text"
                id="total"
                class="form-control"
                :value="trade.Sal.Total"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="cash">現金:</label>
              <input
                type="text"
                id="cash"
                class="form-control"
                :value="trade.Sal.Cash"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="creditCardPrice">信用卡:</label>
              <input
                type="text"
                id="creditCardPrice"
                class="form-control"
                :value="trade.Sal.CreditCardPrice"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="payType1">結帳別1:</label>
              <input
                type="text"
                id="payType1"
                class="form-control"
                :value="trade.Sal.PayType1"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="payType2">結帳別2:</label>
              <input
                type="text"
                id="payType2"
                class="form-control"
                :value="trade.Sal.PayType2"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="easyCardPrice">悠遊卡:</label>
              <input
                type="text"
                id="easyCardPrice"
                class="form-control"
                :value="trade.Sal.EasyCardPrice"
                readonly
              />
            </div>
          </div>
        </div>
        <table-part
          :columns="columns"
          :items="trade.SalDets"
          viewName="reportSalData"
        ></table-part>
      </div>
    </modal-part>
  </div>
</template>

<script>
import modalPart from "@/components/parts/modalPart";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      columns: [
        {
          name: "FoodNumber",
        },
        {
          name: "FoodName",
        },
        {
          name: "Discount",
        },
        {
          name: "Qty",
        },
        {
          name: "SalPrice",
        },
        {
          name: "Total",
        },
      ],
    };
  },
  props: ["trade", "isShow"],
  components: {
    modalPart,
    tablePart,
  },
  methods: {
    onClosed() {
      this.$emit("onClosed");
    },
  },
};
</script>
