<template>
  <div>
    <h2 v-if="module">{{ module.ModuleName }}</h2>
    <div class="row">
      <div class="col-md-6">
        <h5>{{ $t("title.unselectedkind") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-primary py-2 px-4"
              style="border-color: transparent"
              @click="onAdd"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="card-body card-table-body">
            <div class="table-responsive custom-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="allAdd"
                          v-model="allAdd"
                        />
                        <label class="custom-control-label" for="allAdd">{{
                          $t("columnName.add")
                        }}</label>
                      </div>
                    </th>
                    <th>{{ $t("columnName.foodkindnumber") }}</th>
                    <th>{{ $t("columnName.foodkindname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="notSelected in notSelecteds"
                    :key="notSelected.FoodKindId"
                    @click="notSelectedClick(notSelected.FoodKindId)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="notSelected.FoodKindId"
                        v-model="addIds"
                      />
                    </td>
                    <td>{{ notSelected.FoodKindNumber }}</td>
                    <td>{{ notSelected.FoodKindName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5>{{ $t("title.selectedkind") }}</h5>
        <div class="card card-table">
          <div class="card-header">
            <button
              type="button"
              class="btn btn-outline-danger btn-icon py-2 px-4"
              @click="onRemove"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="card-body card-table-body">
            <div class="table-responsive custom-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div class="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="allRemove"
                          v-model="allRemove"
                        />
                        <label class="custom-control-label" for="allRemove">{{
                          $t("columnName.remove")
                        }}</label>
                      </div>
                    </th>
                    <th>{{ $t("columnName.foodkindnumber") }}</th>
                    <th>{{ $t("columnName.foodkindname") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="selected in selecteds"
                    :key="selected.FoodKindId"
                    @click="selectedClick(selected.FoodKindId)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="selected.FoodKindId"
                        v-model="removeIds"
                      />
                    </td>
                    <td>{{ selected.FoodKindNumber }}</td>
                    <td>{{ selected.FoodKindName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      module: {},
      notSelecteds: [],
      selecteds: [],
      addIds: [],
      removeIds: [],
      allAdd: false,
      allRemove: false,
    };
  },
  watch: {
    allAdd(value) {
      this.addIds = [];
      if (value) {
        this.notSelecteds.forEach((item) => this.addIds.push(item.FoodKindId));
      }
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.FoodKindId));
      }
    },
  },
  methods: {
    notSelectedClick(id) {
      const index = this.addIds.indexOf(id);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(id);
      }
    },
    selectedClick(id) {
      const index = this.removeIds.indexOf(id);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(id);
      }
    },
    updateFoodKinds() {
      const vm = this;
      this.$api.modules.getFoodKinds(vm.module.ModuleId).then((data) => {
        vm.addIds = [];
        vm.removeIds = [];
        vm.notSelecteds = data.NotSelecteds;
        vm.selecteds = data.Selecteds;
        vm.allAdd = false;
        vm.allRemove = false;
      });
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇商品類別 !");
        return;
      }
      const vm = this;
      this.$api.modules
        .postFoodKinds({
          ModuleId: vm.module.ModuleId,
          FoodKindIds: vm.addIds,
        })
        .then(() => vm.updateFoodKinds());
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品類別 !");
        return;
      }
      const vm = this;
      this.$api.modules
        .deleteFoodKinds({
          ModuleId: vm.module.ModuleId,
          FoodKindIds: vm.removeIds,
        })
        .then(() => vm.updateFoodKinds());
    },
  },
  created() {
    const vm = this;
    this.$api.modules.get(this.$route.params["id"]).then((data) => {
      if (!data) {
        vm.$func.message.alert("查無此模組 !");
        vm.$router.go(-1);
      }
      vm.module = data;
      vm.updateFoodKinds();
    });
  },
};
</script>
