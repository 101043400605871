<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      :items="customers"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      @getItems="getCustomers"
      @addItem="addCustomer"
      @editItem="editCustomer"
      @removeItem="removeCustomer"
      @trdbClicked="editCustomer"
      :getTrigger="getTrigger"
      :hasAdd="!hasShowmore && !hasDigiwin"
      :hasEdit="!hasShowmore"
      :hasRemove="!hasShowmore && !hasDigiwin"
      :isSortable="true"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchCustomerNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.customernumber") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchCustomerNumber"
                  v-model="searchDTO.CustomerNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchCustomerName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.customername") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchCustomerName"
                  v-model="searchDTO.CustomerName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchCellphone"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.cellphone") }}:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchCellphone"
                  v-model="searchDTO.Cellphone"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label for="degree" class="col-3 col-md-2 col-lg-3 col-form-label"
                >{{ $t("searchAndTablePart.customerdegree") }}:</label
              >
              <div class="col">
                <select
                  class="form-control"
                  id="degree"
                  v-model="searchDTO.Degree"
                >
                  <option
                    v-for="type in degreeTypes"
                    :key="type.value"
                    :value="type.value"
                  >
                    {{ type.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <date-range-part
              :label="$t('searchAndTablePart.createtime')"
              :date="createTime"
              :gridDegree="11"
              @dateChanged="dateChanged"
            />
          </div>
        </div>
      </template>
      <template slot="dataButtonArea" slot-scope="props">
        <button
          v-if="hasVision"
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="openVision(props.index)"
        >
          <i class="fa-solid fa-glasses"></i>
        </button>
      </template>
      <template slot="searchButtonArea">
        <import-part
          v-if="!hasShowmore"
          @onImport="onImport"
          :title="`${$t('modal.importcustomer')}`"
        />
        <export-part v-if="!hasShowmore" @onExport="onExport" />
      </template>
    </search-and-table-part>

    <customer-data
      :item="customer"
      :modalDisplayTrigger="modalDisplayTrigger"
      @onClosed="modalDisplayTrigger = !modalDisplayTrigger"
      @onSaved="saveCustomer"
      :title="modalTitle"
      :isEdit="isEdit"
    ></customer-data>

    <customer-vision
      title="視力紀錄"
      modalId="customerVision"
      modalClass="modal-xl"
      :visionTrigger="visionTrigger"
      :customer="currentCustomer"
      @onClosed="visionOnClosed"
    >
    </customer-vision>
  </div>
</template>

<script>
import dateRangePart from "@/components/parts/dateRangePart";
import inputInlinePart from "@/components/parts/inputInlinePart";
import date from "@/filters/date";

import searchAndTablePart from "@/components/parts/searchAndTablePart";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

import customerVision from "@/components/customers/CustomerVision";

import customerData from "@/components/datas/CustomerData";

export default {
  data() {
    return {
      createTime: {
        DayBegin: date(),
        DayEnd: date(),
      },
      searchDTO: {},
      customers: [],
      pagination: {},
      customer: {},
      modalDisplayTrigger: false,
      modalTitle: "",
      columns: [
        { name: "CustomerNumber" },
        { name: "CustomerName" },
        { name: "Cellphone" },
        { name: "Birth" },
        { name: "Email" },
        { name: "Gender" },
        { name: "Integral" },
        { name: "Degree" },
        { name: "Deposit" },
        { name: "ContactPerson" },
        { name: "StoreNumber" },
        { name: "StoreName" },
        { name: "SalerNumber" },
        { name: "SalerName" },
        { name: "UniformNumber" },
        { name: "Phone1" },
        { name: "Phone2" },
        { name: "Memo" },
        { name: "CreateTime" },
        { name: "DegreeStartDate" },
        { name: "DegreeEndDate" },
      ],
      idProperty: "CustomerId",
      nameProperty: "CustomerName",
      getTrigger: false,
      isEdit: false,
      visionTrigger: false,
      customerVisions: [],
      visionCustomerId: "",
      currentCustomer: {},
      degreeTypes: [],
    };
  },
  watch: {
    "customer.CustomerNumber"(value) {
      if (value && value.endsWith("?")) {
        let number = value.substring(0, value.length - 1);
        this.$api.customers
          .getNextNumber(number)
          .then((data) => (this.customer.CustomerNumber = data));
      }
    },
    currentLang() {
      this.setOptions();
    },
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
    hasVision() {
      return window.localStorage["hasVision"] == "true";
    },
    hasDigiwin() {
      return window.localStorage["erpType"] == 3;
    },
    hasShowmore() {
      return window.localStorage["hasShowmore"] == "true";
    },
  },
  components: {
    dateRangePart,
    inputInlinePart,
    searchAndTablePart,
    importPart,
    exportPart,
    customerVision,
    customerData,
  },
  methods: {
    setOptions() {
      this.degreeTypes = this.$enum.degreeType.getAll();
      this.degreeTypes.splice(0, 0, {
        value: -1,
        name: "None",
        text: `${this.$t("option.degreeType.None")}`,
      });
    },
    openVision(index) {
      const customer = this.customers[index];
      this.currentCustomer = customer;
      this.visionTrigger = !this.visionTrigger;
    },
    visionOnClosed() {
      this.visionTrigger = !this.visionTrigger;
    },
    getCustomers(dTO) {
      const d = { ...dTO, ...this.searchDTO };
      const vm = this;
      this.$api.customers.getPages(d).then((data) => {
        vm.customers = data.Datas;
        vm.pagination = data.Pagination;
        this.$store.dispatch("updateLoading", false);
      });
    },
    addCustomer() {
      this.customer = {};
      this.customer.CountryCode = "886";
      this.modalDisplayTrigger = !this.modalDisplayTrigger;
      this.modalTitle = this.$t("modal.addcustomer");
      this.isEdit = false;
    },
    editCustomer(customer) {
      if (this.hasShowmore) return;
      const vm = this;
      this.$api.customers.get(customer.CustomerId).then((data) => {
        vm.customer = data;
        vm.modalDisplayTrigger = !vm.modalDisplayTrigger;
        vm.modalTitle = this.$t("modal.editcustomer");
        vm.isEdit = true;
      });
    },
    removeCustomer(customer) {
      const vm = this;
      this.$api.customers.remove(customer.CustomerId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveCustomer(item) {
      this.customer = item;
      const vm = this;
      if (this.customer.CountryCode) {
        this.customer.CountryCode = this.customer.CountryCode.replace(
          /[^0-9]/g,
          ""
        );
      }
      this.$api.customers
        .save(vm.customer)
        .then(() => {
          vm.modalDisplayTrigger = !vm.modalDisplayTrigger;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    dateChanged(begin, end) {
      this.createTime.DayBegin = begin;
      this.createTime.DayEnd = end;
      this.searchDTO.CreateBegin = begin;
      this.searchDTO.CreateEnd = end;
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.customers.postExcel(formData).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    onExport() {
      this.$api.customers.getExcel();
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
