<template>
  <div>
    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="onSaved"
      modalId="businessPartModal"
      modalClass="modal-lg"
      title="公司資料"
    >
      <div slot="item">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="businessCompanyName">公司名稱</label>
              <input
                type="text"
                class="form-control"
                id="businessCompanyName"
                v-model="business.CompanyName"
                required
              />
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="businessEnglishName">公司英文名稱</label>
              <input
                type="text"
                class="form-control"
                id="businessEnglishName"
                v-model="business.EnglishName"
                required
              />
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="businessCompanyPhone">公司電話</label>
              <input
                type="text"
                class="form-control"
                id="businessCompanyPhone"
                v-model="business.CompanyPhone"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="businessCompanyAddress">公司地址</label>
              <input
                type="text"
                class="form-control"
                id="businessCompanyAddress"
                v-model="business.CompanyAddress"
                required
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="businessSaleReportType">銷貨單格式</label>
              <select
                class="form-control"
                id="businessSaleReportType"
                v-model="business.SaleReportType"
              >
                <option
                  v-for="saleReportType in saleReportTypes"
                  :key="saleReportType.value"
                  :value="saleReportType.value"
                >
                  {{ saleReportType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="roundType">單據總計計算方式</label>
              <select
                id="roundType"
                class="form-control"
                required
                v-model="business.RoundType"
              >
                <option
                  v-for="roundType in roundTypes"
                  :key="roundType.value"
                  :value="roundType.value"
                >
                  {{ roundType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="detailRoundType">明細小計計算方式</label>
              <select
                id="detailRoundType"
                class="form-control"
                required
                v-model="business.DetailRoundType"
              >
                <option
                  v-for="detailroundType in detailRoundTypes"
                  :key="detailroundType.value"
                  :value="detailroundType.value"
                >
                  {{ detailroundType.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="hasCloudElecInvoice" class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="salPricePrintType">發票明細單價列印模式</label>
              <select
                id="salPricePrintType"
                class="form-control"
                required
                v-model="business.ElecInvoiceSalPricePrintType"
              >
                <option
                  v-for="salPricePrintType in salPricePrintTypes"
                  :key="salPricePrintType.value"
                  :value="salPricePrintType.value"
                >
                  {{ salPricePrintType.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="isERP" class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="stockAvailableType">可用存量計算模式</label>
              <select
                id="stockAvailableType"
                class="form-control"
                required
                v-model="business.StockAvailableType"
              >
                <option
                  v-for="stockAvailableType in stockAvailableTypes"
                  :key="stockAvailableType.value"
                  :value="stockAvailableType.value"
                >
                  {{ stockAvailableType.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="accountReportType">應收帳款格式</label>
              <select
                class="form-control"
                id="accountReportType"
                v-model="business.AccountReportType"
              >
                <option
                  v-for="saleReportType in saleReportTypes"
                  :key="saleReportType.value"
                  :value="saleReportType.value"
                >
                  {{ saleReportType.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="row col-12">
            <div
              v-if="hasCloudElecInvoice"
              class="col-12 col-md-6 col-lg-4 mt-1 mb-3"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isDetailIncludeTax"
                  v-model="business.IsDetailIncludeTax"
                />
                <label class="custom-control-label" for="isDetailIncludeTax"
                  >發票明細金額含稅</label
                >
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mt-1 mb-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isTaxAddition"
                  v-model="business.IsTaxAddition"
                />
                <label class="custom-control-label" for="isTaxAddition"
                  >預設外加稅</label
                >
              </div>
            </div>
          </div>

          <div v-if="isDWAPI" class="digiwin-api-border"></div>
          <div v-if="isDWAPI" class="col-12">
            <div class="py-3 digiwin-api-setting-title">鼎新API</div>
          </div>

          <div v-if="isDWAPI" class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="productBrand">品牌</label>
              <select
                class="form-control"
                id="productBrand"
                v-model="business.DigiwinProductBrandNo"
              >
                <option
                  v-for="no in digiwinProductBrandNos"
                  :key="no.value"
                  :value="no.value"
                >
                  {{ no.text }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="isDWAPI" class="col-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="productCategrory">類別</label>
              <select
                class="form-control"
                id="productCategrory"
                v-model="business.DigiwinProductCategroryNo"
                required
              >
                <option
                  v-for="no in digiwinProductCategroryNos"
                  :key="no.value"
                  :value="no.value"
                >
                  {{ no.text }}
                </option>
              </select>
            </div>
          </div>
          <ul class="col-12 nav nav-tabs mt-2 px-1" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link show active"
                data-toggle="tab"
                href="#businessColorSetting"
                role="tab"
                aria-controls="businessColorSetting"
                aria-selected="true"
                >{{ $t("dataModalPart.food") }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#businessCustomize"
                role="tab"
                aria-controls="businessCustomize"
                aria-selected="true"
                >自訂</a
              >
            </li>
            <li v-if="isCustomer" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#businessCustomer"
                role="tab"
                aria-controls="businessCustomer"
                aria-selected="true"
                >會員</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#businessLineSetting"
                role="tab"
                aria-controls="businessLineSetting"
                aria-selected="true"
                >LINE會員</a
              >
            </li>
          </ul>
          <div class="col-12 tab-content mt-2" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="businessColorSetting"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="row">
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodKindFontSize">{{
                    $t("dataModalPart.foodkindfontsize")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    id="foodKindFontSize"
                    v-model.number="business.FoodKindFontSize"
                    required
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodKindForeColor">{{
                    $t("dataModalPart.foodkindforecolor")
                  }}</label>
                  <input
                    type="color"
                    class="form-control"
                    id="foodKindForeColor"
                    v-model.number="business.FoodKindForeColor"
                    required
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodKindBackColor">{{
                    $t("dataModalPart.foodkindbackcolor")
                  }}</label>
                  <input
                    type="color"
                    class="form-control"
                    id="foodKindBackColor"
                    v-model.number="business.FoodKindBackColor"
                    required
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodFontSize">{{
                    $t("dataModalPart.foodfontsize")
                  }}</label>
                  <input
                    type="number"
                    class="form-control"
                    id="foodFontSize"
                    v-model.number="business.FoodFontSize"
                    required
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodForeColor">{{
                    $t("dataModalPart.foodforecolor")
                  }}</label>
                  <input
                    type="color"
                    class="form-control"
                    id="foodForeColor"
                    v-model.number="business.FoodForeColor"
                    required
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="foodBackColor">{{
                    $t("dataModalPart.foodbackcolor")
                  }}</label>
                  <input
                    type="color"
                    class="form-control"
                    id="foodBackColor"
                    v-model.number="business.FoodBackColor"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="businessCustomize"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="row">
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessPayType1">結帳別1</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessPayType1"
                    v-model="business.PayType1"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessPayType2">結帳別2</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessPayType2"
                    v-model="business.PayType2"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessPeopleType1">客群1</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessPeopleType1"
                    v-model="business.PeopleType1"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessPeopleType2">客群2</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessPeopleType2"
                    v-model="business.PeopleType2"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessPeopleType3">客群3</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessPeopleType3"
                    v-model="business.PeopleType3"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessPeopleType4">客群4</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessPeopleType4"
                    v-model="business.PeopleType4"
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="businessCustomer"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div class="row">
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeZero">零級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeZero"
                    v-model="business.CustomerDegreeZero"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeOne">一級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeOne"
                    v-model="business.CustomerDegreeOne"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeTwo">二級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeTwo"
                    v-model="business.CustomerDegreeTwo"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeThree">三級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeThree"
                    v-model="business.CustomerDegreeThree"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeFour">四級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeFour"
                    v-model="business.CustomerDegreeFour"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeFive">五級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeFive"
                    v-model="business.CustomerDegreeFive"
                  />
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessCustomerDegreeSix">六級</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessCustomerDegreeSix"
                    v-model="business.CustomerDegreeSix"
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="businessLineSetting"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div class="row">
                <div class="col-md-6 col-lg-4 form-group">
                  <label for="businessLiffId">LIFF ID</label>
                  <input
                    type="text"
                    class="form-control"
                    id="businessLiffId"
                    v-model="lineSetting.LineMemberLiffId"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      lineSetting: {},
      business: {},
      roundTypes: [],
      costTypes: [],
      detailRoundTypes: [],
      saleReportTypes: [
        {
          value: 0,
          text: "A4",
        },
        {
          value: 1,
          text: "中一刀",
        },
      ],
      salPricePrintTypes: [],
      stockAvailableTypes: [],
      title: "",
      isShowModal: false,
      digiwinProductBrandNos: [
        { value: 0, text: "無", name: "None" },
        { value: 1, text: "1", name: "1" },
        { value: 2, text: "2", name: "2" },
        { value: 3, text: "3", name: "3" },
        { value: 4, text: "4", name: "4" },
      ],
      digiwinProductCategroryNos: [
        { value: 1, text: "1", name: "1" },
        { value: 2, text: "2", name: "2" },
        { value: 3, text: "3", name: "3" },
        { value: 4, text: "4", name: "4" },
      ],
    };
  },
  props: ["trigger"],
  components: {
    itemModal,
  },
  computed: {
    hasCloudElecInvoice() {
      return (
        window.localStorage["productType"] == 3 ||
        window.localStorage["hasCloudElecInvoice"] == "true"
      );
    },
    isCustomer() {
      return window.localStorage["isCustomer"] == "true";
    },
    isERP() {
      return window.localStorage["isERP"] == "true";
    },
    isDWAPI() {
      return window.localStorage["erpType"] == 3;
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
    trigger() {
      this.isShowModal = !this.isShowModal;
      const vm = this;
      this.$api.businesses.getPart().then((data) => {
        vm.$api.line.getBusiness().then((data) => {
          vm.lineSetting = data;
        });
        vm.business = data;
      });
    },
  },
  methods: {
    setOptions() {
      this.roundTypes = this.$enum.roundType.getAll();
      this.costTypes = this.$enum.costType.getAll();
      this.detailRoundTypes = this.$enum.detailRoundType.getAll();
      this.salPricePrintTypes = this.$enum.salPricePrintType.getAll();
      this.stockAvailableTypes = this.$enum.stockAvailableType.getAll();
    },
    onSaved() {
      if (
        this.isDWAPI &&
        this.business.DigiwinProductBrandNo ==
          this.business.DigiwinProductCategroryNo
      ) {
        this.$func.message.alert("品牌與類別不可相同 !");
        return;
      }
      if (!this.business.DigiwinProductBrandNo) {
        this.business.DigiwinProductBrandNo = 0;
      }
      const vm = this;
      this.$api.businesses
        .postPart(vm.business)
        .then(() => {
          localStorage.setItem("payType1", vm.business.PayType1);
          localStorage.setItem("payType2", vm.business.PayType2);
          vm.$api.line.saveBusiness(vm.lineSetting).then(() => {
            vm.isShowModal = !vm.isShowModal;
            vm.$emit("onSaved");
          });
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>

<style lang="scss">
.digiwin-api-border {
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  padding: 10px 0;
}
.digiwin-api-setting-title {
  color: #00558f;
  font-size: 18px;
  font-weight: bold;
}
</style>
