<template>
  <div>
    <condition-part
      :hasDate="false"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :hasFoodSupplier="true"
      :hasFoodStatusType="true"
      :hasWarehouse="true"
      :hasFoodMajorKind="true"
      :hasFoodKind="true"
      :hasFood="true"
      :hasQtyType="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      :reportTypes="reportTypes"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @printPdf="printPdf"
      @downloadPdf="downloadPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      reportTypes: ["warehouse", "food", "inventory", "available"],
      reportType: "warehouse",
      pagination: {},
    };
  },
  computed: {
    columns() {
      if (this.reportType == "inventory") {
        let s = [
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Barcode" },
          { name: "Qty" },
          { name: "SafeQty" },
          { name: "Unit" },
        ];
        return s;
      } else if (this.reportType == "available") {
        return [
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Barcode" },
          { name: "Qty" },
          { name: "SaleOrderQty", headerText: "訂單未交" },
          { name: "PurchaseOrderQty", headerText: "採購未交" },
          { name: "AvailablyQty" },
          { name: "SafeQty" },
          { name: "Unit" },
          { name: "SalPrice" },
          { name: "Cost" },
          { name: "TotalCost" },
          { name: "SupplierNumber" },
          { name: "SupplierName" },
          { name: "AvgCost" },
          { name: "TotalAvgCost" },
        ];
      } else {
        return [
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Barcode" },
          { name: "Qty" },
          { name: "SafeQty" },
          { name: "Unit" },
          { name: "SalPrice" },
          { name: "Cost" },
          { name: "TotalCost" },
          { name: "SupplierNumber" },
          { name: "SupplierName" },
          { name: "AvgCost" },
          { name: "TotalAvgCost" },
        ];
      }
    },
  },
  components: {
    conditionPart,
  },
  methods: {
    setWarehouses(items) {
      this.warehouseIds = items;
    },
    setFoodKinds(items) {
      this.foodKindIds = items;
    },
    downloadExcel(condition) {
      this.$api.reportExcels.getStock(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getStock(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printStock(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;
      const vm = this;
      this.$api.reports.getStock(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
