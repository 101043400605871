<template>
  <div>
    <page-table
      :parentPage="pagination"
      :items="directorys"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      @getItems="getDirectorys"
      @addItem="addDirectory"
      @editItem="editDirectory"
      @removeItem="removeDirectory"
      @trdbClicked="editDirectory"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      viewName="directorys"
    >
    </page-table>
    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveDirectory"
      :title="modalTitle"
      modalId="directorModal"
    >
      <div slot="item">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="directoryName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="directoryName"
                v-model="directory.DirectoryName"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

export default {
  data() {
    return {
      directorys: [],
      directory: {},
      currentId: "",
      pagination: {},
      isShowModal: false,
      isShowFood: false,
      modalTitle: "",
      columns: [
        {
          name: "DirectoryName",
        },
      ],
      idProperty: "DirectoryId",
      nameProperty: "DirectoryName",
      getTrigger: false,
      authority: {},
    };
  },
  props: ["directoryKindId"],
  components: {
    pageTable,
    itemModal,
  },
  watch: {
    directoryKindId() {
      this.getTrigger = !this.getTrigger;
    },
  },
  methods: {
    getDirectorys(dTO) {
      const vm = this;
      if (!vm.directoryKindId || vm.directoryKindId.length == 0) return;
      this.$api.directorys.getPages(vm.directoryKindId, dTO).then((data) => {
        vm.directorys = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addDirectory() {
      if (!this.directoryKindId || this.directoryKindId.length == 0) {
        this.$func.message.alert("請選擇一筆類別 !");
        return;
      }
      this.directory = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.adddirectory");
    },
    editDirectory(directory) {
      const vm = this;
      this.$api.directorys.get(directory.DirectoryId).then((data) => {
        vm.directory = data;
        vm.isShowModal = !vm.isShowModal;
        this.modalTitle = this.$t("modal.editdirectory");
      });
    },
    removeDirectory(directory) {
      const vm = this;
      this.$api.directorys.remove(directory.DirectoryId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveDirectory() {
      this.directory.DirectoryKindId = this.directoryKindId;
      const vm = this;
      this.$api.directorys
        .save(vm.directory)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
