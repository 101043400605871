export function getAll() {
  var items = [
    {
      value: 0,
      name: "Production",
      text: "正式",
    },
    {
      value: 1,
      name: "Sandbox",
      text: "Sandbox",
    },
  ];
  return items;
}
