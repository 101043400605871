import i18n from "@/langs/i18n";

export function getAll() {
  let c = [
    {
      value: -1,
      name: "All",
      text: `${i18n.t("option.reportSourceType.All")}`,
    },
    {
      value: 0,
      name: "Local",
      text: `${i18n.t("option.reportSourceType.Local")}`,
    },
  ];
  if (window.localStorage["hasOnlineOrder"] == "true") {
    c.push({
      value: 1,
      name: "Web",
      text: `${i18n.t("option.reportSourceType.Web")}`,
    });
  }
  if (window.localStorage["hasUberEats"] == "true") {
    c.push({
      value: 2,
      name: "Ubereats",
      text: `${i18n.t("option.reportSourceType.Ubereats")}`,
    });
  }
  if (
    window.localStorage["isERP"] == "true" ||
    window.localStorage["hasCloudElecInvoice"] == "true"
  ) {
    c.push(
      c.push({
        value: 4,
        name: "Backstage",
        text: `${i18n.t("option.reportSourceType.Backstage")}`,
      })
    );
  }
  return c;
}

export function getByValue(value) {
  const type = getAll().find(function (item) {
    return item.value == value;
  });
  return type;
}
